//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { PlanPermissions } from '@/modules/plan/plan-permissions';
import moment from 'moment';

import { PlanModel } from '@/modules/plan/plan-model';
const { fields } = PlanModel;

export default {
  name: 'app-plan-view-card',

  props: ['values'],

  data() {
    return {
      selectedID: null,
      dialogVisible: false,
      modalVisible: false,
      statusLoading: false,
      
      model: null,
      places: [],
      images: [],
      isDisablePlan: false,
    }
  },
  computed: {
    ...mapGetters({
      record: 'plan/view/record',
      loading: 'plan/view/loading',
      destroyLoading: 'plan/destroy/loading',
      changeStatusLoading: 'plan/form/changeStatusLoading',

      is_screen_xs: 'layout/is_screen_xs',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new PlanPermissions(this.currentUser).edit;
    },
    hasPermissionToDestroy() {
      return new PlanPermissions(this.currentUser).destroy;
    },
    displayPlaces() {
      return this.places.slice(0, 3);
    },
    displayImages() {
      return this.images.slice(0, 4);
    },
  },

  mounted() {
    this.values.programs.forEach(program => {
      program.places.forEach(place => {
        if (place.place) this.places.push(place.place.placeName)
        if (place.selectedImage) this.images.push(place.selectedImage)
      })
    });
  },
  methods: {
    ...mapActions({
      doFind: 'plan/view/doFind',
      doDestroy: 'plan/destroy/doDestroy',
      doDisable: 'plan/form/doDisable',
      doEnable: 'plan/form/doEnable',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      return PlanModel.presenter(row, fieldName);
    },
    presenterMap(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      return val[this.currentLanguageCode]
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },
    onOpenModal(id) {
      this.selectedID = id;
      this.modalVisible = true;
      this.dialogVisible = true;
    },
    onModalClose() {
      this.selectedID = null;
      this.dialogVisible = false;
      setTimeout(() => {
        this.modalVisible = false;
      }, 200);
    },
    async doDestroyWithConfirm(id) {
      try {
        await this.doDestroy(id);
        this.onModalClose();
      } catch (error) {
        // no
      }
    },
    async doDisablePlan() {
      this.statusLoading = true
      const id = this.values.id
      await this.doDisable(id)
      this.values.disabled = true
      this.statusLoading = false
    },
    async doEnablePlan() {
      this.statusLoading = true
      const id = this.values.id
      await this.doEnable(id)
      this.values.disabled = false
      this.statusLoading = false
    },
  },
};
