//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import PlanViewCard from '@/modules/plan/components/plan-view-card.vue';
import PlanViewPlaceCard from '@/modules/plan/components/plan-view-place-card.vue';
import moment from 'moment';
import _lodash from 'lodash';

import { PlanModel } from '@/modules/plan/plan-model';
const { fields } = PlanModel;

export default {
  name: 'app-plan-view-page',
  props: ['id'],
  components: {
    [PlanViewCard.name]: PlanViewCard,
    [PlanViewPlaceCard.name]: PlanViewPlaceCard,
  },

  data() {
    return {
      slide: 1,
      slide2: 1,
      tab: 1,
      programs: [],
      images: [],
    };
  },

  computed: {
    ...mapGetters({
      record: 'plan/view/record',
      loading: 'plan/view/loading',
      changeStatusLoading: 'plan/form/changeStatusLoading',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    fields() {
      return fields;
    },
    values() {
      const rows = []
      if (this.record) {
        rows.push(this.record)
      }
      return rows      
    }
  },

  async mounted() {
    await this.doFind(this.id);

    if (this.record) {
      for (let index = 0; index < this.record.programs.length; index++) {
        const places = this.findPlacesInDay(index)
        this.programs.push(places)
      }

      if (this.record.images && this.record.images.length) {
        this.record.images.forEach(image => {
          _lodash.isEmpty(image)
            ? this.images.push('/images/no-image.svg')
            : this.images.push(image) 
        });
      } else {
        this.images.push('/images/no-image.svg')
      }
    }
  },
  methods: {
    ...mapActions({
      doFind: 'plan/view/doFind',
      doToggleStatus: 'plan/form/doToggleStatus',
    }),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      return val ? val : 'ـــ';
    },
    presenterMap(row, fieldName) {
      const val = PlanModel.presenter(row, fieldName);
      const valueEn = val ? val['en'] : null
        const valueAr = val ? val['ar'] : null
        const value = this.currentLanguageCode == 'ar'
          ? valueAr ? valueAr : valueEn
          : valueEn ? valueEn : valueAr
        return value ? value : 'ـــ'
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },

    findPlacesInDay(day) {
      const places = []

      if (this.record && this.record.programs && this.record.programs.length > day) {
        const dayProgram = this.record.programs[day]
        dayProgram.places.forEach(element => {
          const place = { ...element.place, ...element }
          delete place.place
          places.push(place)
        });
      }

      return places
    },

    async toggleStatus() {
      const id = this.record.id
      const status = !this.record.enabled
      this.doToggleStatus({
        id, 
        status
      })
    },
  },
};
