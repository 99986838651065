import { render, staticRenderFns } from "./plan-view-card.vue?vue&type=template&id=4925e90b&scoped=true&"
import script from "./plan-view-card.vue?vue&type=script&lang=js&"
export * from "./plan-view-card.vue?vue&type=script&lang=js&"
import style0 from "./plan-view-card.vue?vue&type=style&index=0&id=4925e90b&lang=scss&scoped=true&"
import style1 from "./plan-view-card.vue?vue&type=style&index=1&id=4925e90b&lang=scss&scoped=true&"
import style2 from "./plan-view-card.vue?vue&type=style&index=2&id=4925e90b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4925e90b",
  null
  
)

export default component.exports
import {QCard,QCardSection,QBadge,QImg,QBtn,QSpinnerIos,QCardActions,QDialog} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QBadge,QImg,QBtn,QSpinnerIos,QCardActions,QDialog})
