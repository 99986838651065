//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import _lodash from 'lodash';

const loremTitle = 'Lorem Ipsum'
const lorem = `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.`
      
export default {
  name: 'app-plan-view-place-card',

  props: {
    place: {
      type: Object,
      default: () => {
        return {
          id: '0',
          placeName: { en: '', ar: '' },
          description: { en: '', ar: '' },
          address: { en: '', ar: '' },
          country: { en: '', ar: '' },
          city: { id: '', country_id: '', name: { en: '', ar: '' } },
          placePictures: [
            { publicUrl: '/images/no-image.svg' },
            { publicUrl: '/images/no-image.svg' },
            { publicUrl: '/images/no-image.svg' },
            { publicUrl: '/images/no-image.svg' },
          ],
          selectedImage: '/images/no-image.svg',
          placeType: '',
          visitCount: 0,
          averageTime: 0,
        }
      }
    },
  },

  data() {
    return {
      isMore: false,
      record: {
        id: '0',
        placeName: { en: loremTitle, ar: loremTitle },
        description: { en: lorem, ar: lorem },
        address: { en: loremTitle, ar: loremTitle },
        country: { en: loremTitle, ar: loremTitle },
        city: { id: '', country_id: '', name: { en: loremTitle, ar: loremTitle } },
        placePictures: [
          { publicUrl: '/images/no-image.svg' },
          { publicUrl: '/images/no-image.svg' },
          { publicUrl: '/images/no-image.svg' },
          { publicUrl: '/images/no-image.svg' },
        ],
        selectedImage: '/images/no-image.svg',
        placeType: '',
        visitCount: 0,
        averageTime: 0,
      },
      images: [],
    }
  },
  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
      currentUser: 'auth/currentUser',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    displayImages() {
      return this.images.slice(0, 4);
    },
    readMore() {
      return this.isMore
    },
  },

  mounted() {
    if (!_lodash.isEmpty(this.place)) {
      this.record = this.cast(this.place)
    }

    this.record.placePictures.forEach(image => {
      _lodash.isEmpty(image.publicUrl) 
        ? this.images.push('/images/no-image.svg')
        : this.images.push(image.publicUrl)
    });
  },
  methods: {
    ...mapActions({}),
    i18n(key, args) {
      return this.$t(key, args);
    },
    presenter(row, fieldName) {
      const val = row[fieldName];
      if (fieldName == 'averageTime') {
        return this.currentLanguageCode == 'ar'
          ? `دقيقة ${val}`
          : `${val} Minutes`
      }
      return val
    },
    presenterMap(row, fieldName) {
      const val = row[fieldName]
      if (fieldName == 'city') {
        return val && val['name'] ? val.name[this.currentLanguageCode] : '___'
      }
      const valueEn = val ? val['en'] : null
      const valueAr = val ? val['ar'] : null
      const value = this.currentLanguageCode == 'ar'
        ? valueAr ? valueAr : valueEn
        : valueEn ? valueEn : valueAr
      return value ? value : 'ـــ'
      // return val ? val[this.currentLanguageCode] : 'ـــ'
    },
    presenterDateTime(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("DD-MM-YYYY h:mm A") : 'ـــ'; 
    },
    presenterDate(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("D MMM, YYYY") : 'ـــ'; 
    },
    presenterDay(row, fieldName) {
      const val = row[fieldName]
      return val ? moment(val).locale(this.currentLanguageCode).format("dddd") : 'ـــ'; 
    },
    presenterTime(row, fieldName) {
      const date = row[fieldName]
      return moment(date, "YYYY-MM-DD'T'hh:mm:ss.SSS'Z'").locale(this.currentLanguageCode).format('hh:mm a')
    },

    cast(model) {
      const city = model.city || {}
      
      return {
        id: model.id || '0',
        placeName: model.placeName || { en: loremTitle, ar: loremTitle },
        description: model.description || { en: lorem, ar: lorem },
        address: model.address || { en: loremTitle, ar: loremTitle },
        country: model.country || { en: loremTitle, ar: loremTitle },
        city: { 
          id: city['id'] || '', 
          country_id: city['country_id'] || '', 
          name: city['name'] || { en: loremTitle, ar: loremTitle } 
        },
        placePictures: model.placePictures || [
          { publicUrl: '/images/no-image.svg' },
          { publicUrl: '/images/no-image.svg' },
          { publicUrl: '/images/no-image.svg' },
          { publicUrl: '/images/no-image.svg' },
        ],
        selectedImage: model.selectedImage || '/images/no-image.svg',
        placeType: model.placeType || loremTitle,
        visitCount: model.visitCount || 0,
        averageTime: model.averageTime || 0,
      }
    }
  },
};
